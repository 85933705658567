import React, { lazy, Suspense, useEffect } from 'react'
import './scss/App.scss'
import { createBrowserHistory } from 'history'
import { Provider } from 'mobx-react'
import { Route, Router, Switch } from 'react-router-dom'

import {
  authorStore,
  categoriesStore,
  contactStore,
  postStore,
  subscriptionStore,
  testimonialStore,
  toastStore,
  signUpContentStore,
  localizationStore,
} from './store'

const stores = {
  categoriesStore,
  toastStore,
  authorStore,
  postStore,
  subscriptionStore,
  contactStore,
  testimonialStore,
  signUpContentStore,
  localizationStore,
}

const HomePage = lazy(() => import('./components/pages/HomePage'))
const AboutUs = lazy(() => import('./components/pages/AboutUs'))
const AdminPage = lazy(() => import('./components/pages/Admin'))
const BlogDetailPage = lazy(() => import('./components/pages/BlogDetailPage'))
const BlogPage = lazy(() => import('./components/pages/BlogPage'))
const CareerPage = lazy(() => import('./components/pages/Careers'))
const JobApply = lazy(() => import('./components/pages/Careers/JobApply'))
const JobPage = lazy(() => import('./components/pages/Careers/JobPage'))
const JobDetail = lazy(() => import('./components/pages/Careers/JobDetail'))
const ContactPage = lazy(() => import('./components/pages/ContactPage'))
const FeaturesPage = lazy(() => import('./components/pages/Features'))
const RequestDemo = lazy(() => import('./components/pages/RequestDemo'))
const TermsAndConditionPage = lazy(() => import('./components/pages/TermsAndConditions/TermsAndConditionPage'))
const PrivacyPolicyPage = lazy(() => import('./components/pages/PrivacyPolicy/PrivacyPolicyPage'))
const TestimonialPage = lazy(() => import('./components/pages/Testimonial'))
const TestimonialDetailPage = lazy(() => import('./components/pages/TestimonialDetail'))
// const Pricing = lazy(() => import('./components/pages/Pricing'))

const LoadingComponent = () => (
  <div
    style={{
      background: '#ffffff',
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      zIndex: 1000,
      width: '100vw',
    }}></div>
)

const MainComponent = () => (
  <Suspense fallback={<LoadingComponent />}>
    <Switch>
      <Route exact path="/" component={HomePage} />
      <Route exact path="/about-us" component={AboutUs} />
      <Route exact path="/blog-detail/:blogId" component={BlogDetailPage} />
      <Route exact path="/blog" component={BlogPage} />
      <Route exact path="/careers" component={CareerPage} />
      <Route exact path="/careers/job-apply/:jobId?" component={JobApply} />
      <Route exact path="/careers/job-detail/:jobId" component={JobDetail} />
      <Route exact path="/careers/jobs" component={JobPage} />
      <Route exact path="/contact" component={ContactPage} />
      <Route exact path="/features" component={FeaturesPage} />
      <Route exact path="/request-demo" component={RequestDemo} />
      <Route exact path="/terms-and-conditions" component={TermsAndConditionPage} />
      <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />
      <Route exact path="/testimonial-detail/:testimonialId" component={TestimonialDetailPage} />
      <Route exact path="/testimonial" component={TestimonialPage} />
      {/* <Route exact path="/pricing" component={Pricing} /> */}
      <Route path="/otrafy-cms" component={AdminPage} />
    </Switch>
  </Suspense>
)

const App = () => {
  const history = createBrowserHistory()

  useEffect(() => {
    const googleHeadScript = document.createElement('script')
    googleHeadScript.type = 'text/javascript'
    googleHeadScript.async = true
    googleHeadScript.innerHTML = `
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        const f = d.getElementsByTagName(s)[0];
        const j = d.createElement(s);
        const dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-P7H526KL');`

    const googleBodyNoScript = document.createElement('noscript')
    googleBodyNoScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-P7H526KL" height="0" width="0" style="display:none;visibility:hidden"></iframe>`

    document.head.appendChild(googleHeadScript)
    document.body.appendChild(googleBodyNoScript)

    return () => {
      document.head.removeChild(googleHeadScript)
      document.body.removeChild(googleBodyNoScript)
    }
  }, [])

  return (
    <Provider {...stores}>
      <Router history={history}>
        <MainComponent />
      </Router>
    </Provider>
  )
}

export default App
