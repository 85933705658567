import merge from 'lodash/merge'

const config = {
  all: {
    env: process.env.NODE_ENV || 'development',
    isDev: process.env.NODE_ENV !== 'production',
    basename: process.env.PUBLIC_URL,
    isBrowser: typeof window !== 'undefined',
  },
  test: {
    apiUrl: window.location.origin,
    apiOtrafyUrl: "https://otrafy-demo.azurewebsites.net",
  },
  development: {
    apiUrl: "http://localhost:5000",
    apiOtrafyUrl: "https://otrafy-demo.azurewebsites.net",
  },
  production: {
    apiUrl: window.location.origin,
    apiOtrafyUrl: window.location.origin === 'https://www.otrafy.com' ? 'https://application.otrafy.com' : "https://otrafy-demo.azurewebsites.net",
  },
}

const configMerged = merge(config.all, config[config.all.env])

export const {
  env,
  isDev,
  basename,
  isBrowser,
  apiUrl,
  apiOtrafyUrl,
} = configMerged
