export const apiPath = {
  API_GET_CATEGORY: '/api/Category/Search',
  API_CATEGORY: '/api/Category',
  API_GET_ALL_POST: '/api/Post/Search',
  API_POST: '/api/Post',
  API_POST_GETATTACHMENT: '/api/Post/GetAttachment',
  API_GET_ALL_AUTHOR: '/api/Author/Search',
  API_AUTHOR: '/api/Author',
  API_GET_CATEGORY_OPTIONS: '/api/Category/GetCategoryOptions',
  API_CUSTOMER_REQUEST: '/api/RequestDemo',
  API_GET_NEWS_FEED: '/api/Post/GetNewsfeed',
  API_GET_SUBSCRIPTION: '/api/Subscription/Search',
  API_SUBSCRIPTION: '/api/Subscription',
  API_GET_CUSTOMER_REQUEST: '/api/RequestDemo/Search',
  API_TESTIMONIAL: '/api/Testimonial',
  API_TESTIMONIAL_GETIMAGE: '/api/Testimonial/GetImage',
  API_TESTIMONIAL_GETIMAGETHUMBNAIL: '/api/Testimonial/GetImageThumbnail',
  API_TESTIMONIAL_GETLOGOTHUMBNAIL: '/api/Testimonial/GetLogoThumbnail',
  API_GET_ALL_TESTIMONIAL: '/api/Testimonial/GetAll',
  API_ADMIN_GET_ALL_TESTIMONIAL: '/api/Testimonial/Search',
  API_LOCATION: '/api/Location',
  API_DEPARTMENT: '/api/Department',
  API_JOBTYPE: '/api/JobType',
  API_CAREER: '/api/Career',
  API_CANDIDATE: '/api/Candidate',
  API_ALL_CONTACT: '/api/Contact/Search',
  API_CONTACT: '/api/Contact',
  API_SIGN_UP_CONTENT: '/api/SignUpContent',
  API_SUBSCRIPTION_PLAN: '/api/admin/GetUserPricingPlans',
  API_GET_LOCALIZATION_DATA: '/api/account/GetLocalizationData',
}